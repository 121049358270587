$(function(){
	if( $('body').hasClass('main-page') ) {
		var main_swiper = new Swiper('.main-banner-contents.swiper-container', {
			effect: 'fade',
			allowTouchMove:false,
			autoHeight:true
		});
		$('.main-banner-contents').data('swiper', main_swiper);


		$('.main-banner-menu a').on('click',function(e) {
			e.preventDefault();
			var idx = $(this).parent('li').index();
			$('.main-banner-contents').data('swiper').slideTo(idx);
			$(this).parent('li').siblings('li').removeClass('on').end().addClass('on');
			$('.main-banner-menu').removeClass('v1 v2 v3 v4').addClass('v'+(idx+1));
			return false;
		});

		$(window).on('resize.interview',function() {
			if( $(window).width() > 1279 ) {
				var bodyheight =0 ;
				$('.main-interview-box .interview-box .body').css('height','auto');
				$('.main-interview-box .interview-box .body').each(function() {
					if( bodyheight < $(this).outerHeight() ) bodyheight = $(this).outerHeight();
				});
				$('.main-interview-box .interview-box .body').css('height', bodyheight);
			}
		}).trigger('resize.interview');

		var interview_swiper = new Swiper('.main-interview-box.swiper-container', {
			autoHeight:true,
			navigation: {
				nextEl: '.main-interview-box .swiper-button-next',
				prevEl: '.main-interview-box .swiper-button-prev',
			},
			breakpoints: {
				1280: {
					slidesPerView: 2,
					spaceBetween: 40,
					autoHeight:false
				}
			}
		});


	}
});

