$(function(){
	$('.header-wrap .bt-gnb-toggle').on('click',function(e) {
		e.preventDefault();
		$('.header-wrap').toggleClass('open');
		return false;
	});

	$('.bt-submit-layer , .submit-layer .bt-close').on('click',function(e) {
		e.preventDefault();
		$('.submit-layer').toggleClass('open');
		return false;
	});
});