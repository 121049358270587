$(function(){
	if( $('body').hasClass('team-page') ) {
		$('.team-member-list .team-member-box').each(function(a,b) {
			$(this).attr('data-idx',a+1).data('idx', a+1);
		});
		$('.team-member-list .team-member-profile .link').on('click',function(e) {
			e.preventDefault();
			var target = $($(this).attr('href')),
				box = $(this).closest('.team-member-box'),
				idx = box.data('idx'),
				len = 2;
			if( $(window).width() > 1279 ) len = 3;
			if( $(window).width() > 1920 ) len = 4;
			if( target.length == 0 ) return false;
			if( ! idx ) return false;
			if( box.hasClass('on') ) return false;
			// 자신의 idx 에서 모바일떄와 pc때의 idx가 다르다..
			var cal = Math.ceil( parseInt(idx) / len ) * len-1;
			if( cal > $('.team-member-list .team-member-box').length - 1) { 
				cal = $('.team-member-list .team-member-box').length - 1;
			}
			var where = $('.team-member-list .team-member-box').eq(cal);
			

			if( where.next('.team-member-info-viewer').length > 0 ) {

				where.next('.team-member-info-viewer').fadeOut('fast',function() {
					$(this).html(target.html()).fadeIn('fast');
				});
				/*
				$('.team-member-list .team-member-info-viewer').slideUp(400, function() {
					
					$(this).remove();
					$('<div class="company-info-viewer"></div>').html(target.html()).insertAfter( where );
					$('.team-member-list .team-member-info-viewer').slideDown();
				});
				*/
			} else {
				$('.team-member-list .team-member-info-viewer').remove();

				$('<div class="team-member-info-viewer"></div>').html(target.html()).insertAfter( where );
				$('.team-member-list .team-member-info-viewer').fadeIn('fast');
			}

			$('.team-member-list .team-member-box').removeClass('on');
			box.addClass('on');
			return false;
		});

		$(document).on('click', '.company-info-viewer .close' ,function(e) {
			e.preventDefault();
			$('.team-member-info-viewer').fadeOut('fast', function(){
				$('.team-member-list .team-member-box').removeClass('on');
				$(this).remove();	
			});
			return false;
		});


		var teambanner_swiper = new Swiper('.team-slogan-wrap .swiper-container', {
			autoHeight:false, 
			loop:true,
			navigation: {
				nextEl: '.team-slogan-wrap .swiper-button-next',
				prevEl: '.team-slogan-wrap .swiper-button-prev',
			}
		});

	}
});

