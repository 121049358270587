$(function(){
	if( $('body').hasClass('company-page') ) {
		$('.company-list .company-box').each(function(a,b) {
			$(this).attr('data-idx',a+1).data('idx', a+1);
		});
		$('.company-list .company-logo .link').on('click',function(e) {
			e.preventDefault();
			var target = $($(this).attr('href')),
				box = $(this).closest('.company-box'),
				idx = box.data('idx'),
				len = 2;
			if( $(window).width() > 1279 ) len = 4;
			if( target.length == 0 ) return false;
			if( ! idx ) return false;
			if( box.hasClass('on') ) return false;
			// 자신의 idx 에서 모바일떄와 pc때의 idx가 다르다..
			var cal = Math.ceil( parseInt(idx) / len ) * len-1;
			if( cal > $('.company-list .company-box').length - 1) { 
				cal = $('.company-list .company-box').length - 1;
			}

			var where = $('.company-list .company-box').eq(cal);
			

			if( where.next('.company-info-viewer').length > 0 ) {

				where.next('.company-info-viewer').fadeOut('fast',function() {
					$(this).html(target.html()).fadeIn('fast');
				});
			} else {
				$('.company-list .company-info-viewer').remove();

				$('<div class="company-info-viewer"></div>').html(target.html()).insertAfter( where );
				$('.company-list .company-info-viewer').fadeIn('fast');
			}

			$('.company-list .company-box').removeClass('on');
			box.addClass('on');



			return false;
		});
		$(document).on('click', '.company-info-viewer .close' ,function(e) {
			e.preventDefault();
			$('.company-info-viewer').fadeOut('fast', function(){
				$('.company-list .company-box').removeClass('on');
				$(this).remove();	
			});
			return false;
		});
	}
});

